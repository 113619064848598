<template>
  <div class="partner">
    <div class="container">
      <div class="about-title">
        <h1 class="primary-title">广告投放</h1>
        <h2 class="secondary-title">ADVERTISEMENT</h2>
      </div>
      <div class="about-content">
        <div class="about-item-content">
          <div class="about-item-title">广告投放</div>
          <div class="about-item-body">
            <p>
              嗨喵科技拥有大量的线下活动流量和线上互动垂直流量，目前提供的广告投放类型包括以下种类:
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">图片广告位</div>
          <div class="about-item-body">
            <p>
              站内各种尺寸的图片广告位出售，最低购买时长为3个月；一次性购买半年，优惠10%；一次性购买一年，优惠15%。
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">大屏广告牌</div>
          <div class="about-item-body">
            <p>
              本站很多文章在Google和百度都拥有良好的关键词排名。你可以选择购买某个页面中的关键词链接，或是购买全站的某个关键词链接。还可以在你指定的文章内添加和你产品相关的广告，提高关联性。
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">新闻投稿</div>
          <div class="about-item-body">
            <p>
              本站很多文章在Google和百度都拥有良好的关键词排名，仅接受活动策划、主持人、婚庆、司仪、酒吧、校园活动、商演等与本站内容相关的付费稿件，投稿内容将呈现在新闻动态模块。
            </p>
            <p>
              除了以上形式外，我们也非常乐意接受其它任何能够能够双赢的合作。
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">详情洽谈</div>
          <div class="about-item-body">
            <p>热线电话：400-082-2298</p>
            <p>电话/微信：19512368757</p>
          </div>
        </div>
      </div>
    </div>
    <myFooter />
  </div>
</template>

<script>
import myFooter from '@/components/myFooter.vue';

export default {
  name: 'advertisement',
  metaInfo: {
    title: '嗨喵悦动 - 嗨喵悦动登录_嗨喵悦动大屏互动',
    meta: [
      {
        name: 'keywords',
        content:
          '嗨喵悦动,嗨喵悦动大屏互动,嗨喵悦动官网,嗨喵悦动登录,微信墙,微信互动,现场互动,嗨现场',
      },
      {
        name: 'description',
        content:
          '嗨喵悦动是一款免费便捷炫酷的活动大屏幕现场互动软件，适用于企业年会、酒吧、婚礼、商业庆典峰会等活动现场，包括微信上墙送祝福、扫码签到、抽奖投票、摇一摇宏包雨等有趣的大屏互动游戏，让活动更精彩！',
      },
    ],
  },
  components: { myFooter },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  position: relative;
}
.banner > .img {
  width: 100%;
  height: 50%;
  background-color: #f9f9f9;
  vertical-align: middle;
  border: 0;
}

.about-title {
  top: 15%;
  padding: 15px 37.5px;
  width: 100%;
  color: #000;
  position: relative;
}

.primary-title {
  font-size: 30px;
}

.secondary-title {
  margin-top: 7.5px;
  padding-left: 22.5px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Times New Roman", serif;
}

.container {
  position: relative;
}

.about-content {
  margin: 0px auto 0;
  padding: 22.5px 37.5px;
  width: 100%;
  max-width: 817.5px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 15px 11.25px -3.75px #d3cfc5;
}

.about-item-content {
  margin-top: 7.5px;
}

.about-item-title {
  color: #ff4141;
  font-size: 12px;
  margin-bottom: 15px;
  font-weight: bolder;
}

.about-item-body {
  margin-bottom: 22.5px;
  line-height: 1.5;
  text-indent: 2em;
}

p {
  margin: 0 0 7.5px;
}
</style>
